import React from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {Transposit} from "transposit";
import Button from 'react-bootstrap/Button';
import FormCheckInput from "react-bootstrap/FormCheckInput";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import FormGroup from "react-bootstrap/FormGroup";
import FormControl from "react-bootstrap/FormControl";
import FormCheck from "react-bootstrap/FormCheck";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import NavLink from "react-bootstrap/NavLink";
import Nav from "react-bootstrap/Nav";
import NavItem from "react-bootstrap/NavItem";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import {LinkContainer} from "react-router-bootstrap"

// At the top of App.js
const transposit = new Transposit(
    "https://mailmetadata-j2ob0.transposit.io" // replace with your app's URL
);

function SignIn() {
    return (
        <Button onClick={e => {
            transposit.signIn(`${window.location.origin}/handle-signin`);
        }}>
            Sign In
        </Button>
    );
}

function SearchContacts({history}) {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [sentOnly, setSentOnly] = React.useState(true);
    // Call your backend
    const [data, setData] = React.useState(null);
    const [mode, setMode] = React.useState(null);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCheckBoxChange = (event) => {
        setSentOnly(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setMode("Searching");
        transposit
            .run("contacts", {search: searchTerm, sent_only: sentOnly})
            .then(({results}) => {
                setData(results);
                setMode("Done");
            });
    };

    const table = (data) => {
        return <Table>
            <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Latest</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, key) =>
                <tr key={key}>
                    <td>{item.NAME}</td>
                    <td>{item.EMAIL}</td>
                    <td>{item.LATEST}</td>
                </tr>
            )}
            </tbody>
        </Table>;
    };

    const results = mode === "Done" ? table(data) :
        mode === "Searching" ? <ProgressBar>Searching...</ProgressBar> : "";

    return (
        <>
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    <label>Search Contacts:</label>
                    <FormControl type="text" value={searchTerm} onChange={handleChange}/>
                </FormGroup>
                <FormCheck>

                    <FormCheckInput type="checkbox" checked={sentOnly} onChange={handleCheckBoxChange}/>
                    <FormCheckLabel>Sent only</FormCheckLabel>
                </FormCheck>
                <Button type="submit">Search</Button>
            </form>
            {results}
        </>
    );
}

function Navigation() {
    return (
        <LoggedInContext.Consumer>
            {props => {
                return <Navbar>
                    <NavbarBrand>
                        {props.user ? props.user.name : ""}
                    </NavbarBrand>
                    <Nav>
                        <LinkContainer to="/" exact>
                            <NavLink><NavItem>Home</NavItem></NavLink>
                        </LinkContainer>
                        <LinkContainer to="/contacts" exact>
                            <NavLink><NavItem>Contacts</NavItem></NavLink>
                        </LinkContainer>
                        <LinkContainer to="/conversations" exact>
                            <NavLink><NavItem>Conversations</NavItem></NavLink>
                        </LinkContainer>

                        <NavItem>
                            <NavLink onClick={() => {
                                transposit.signOut(`${window.location.origin}/signin`);
                            }}>
                                Sign Out
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>
            }}
        </LoggedInContext.Consumer>
    );
}

function Contacts() {
    return (
        <Container>
            <SearchContacts/>
        </Container>
    );
}

function Dashboard() {
    const [distinct, setDistinct] = React.useState(null);
    const [top, setTop] = React.useState(null);
    const [topRecent, setTopRecent] = React.useState(null);

    React.useEffect(() => {
        transposit
            .run("distinct_sent_contacts", {})
            .then(({results}) => {
                setDistinct(results);
            });
    }, []);
    React.useEffect(() => {
        transposit
            .run("top_contacts", {})
            .then(({results}) => {
                setTop(results);
            });
    }, []);
    React.useEffect(() => {
        transposit
            .run("top_contacts_last_year", {})
            .then(({results}) => {
                setTopRecent(results);
            });
    }, []);

    return (
        <Container>
            <div>
                <h2>Conversation History</h2>
            </div>
            <div>
                <span>Distinct Names:</span> <span>{distinct ? distinct[0].DISTINCT_NAMES : "-"}</span>
            </div>
            <div>
                <span>Distinct Addresses:</span> <span>{distinct ? distinct[0].DISTINCT_EMAILS : "-"}</span>
            </div>
            <div>
                <span>Earliest:</span> <span>{distinct ? distinct[0].EARLIEST : "-"}</span>
            </div>
            <div>
                <span>Latest:</span> <span>{distinct ? distinct[0].LATEST : "-"}</span>
            </div>
            <div>
                <h3>Top contacts</h3>
            </div>
            {top ? top.map((item, key) =>
                <div key={key}>
                    <span>{item.NAME} &lt;{item.EMAIL}&gt;</span>
                </div>
            ) : "Loading..."}
            <div>
                <h3>Top recent contacts</h3>
            </div>
            {topRecent ? topRecent.map((item, key) =>
                <div key={key}>
                    <span>{item.NAME} &lt;{item.EMAIL}&gt;</span>
                </div>
            ) : "Loading..."}
        </Container>
    );
}

function Conversations() {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [sentOnly, setSentOnly] = React.useState(true);
    // Call your backend
    const [data, setData] = React.useState(null);
    const [mode, setMode] = React.useState(null);

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCheckBoxChange = (event) => {
        setSentOnly(event.target.checked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setMode("Searching");
        transposit
            .run("conversations", {search: searchTerm, sent_only: sentOnly})
            .then(({results}) => {
                setData(results);
                setMode("Done");
            });
    };

    const table = (data) => {
        return <Table>
            <thead>
            <tr>
                <th scope="col">From</th>
                <th scope="col">To</th>
                <th scope="col">Received</th>
                <th scope="col">Subject</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, key) =>
                <tr key={key}>
                    <td>{item.FROM_NAME} &lt;{item.FROM_EMAIL}&gt;</td>
                    <td>{item.TO_NAME} &lt;{item.TO_EMAIL}&gt;</td>
                    <td>{item.RECEIVED}</td>
                    <td>{item.SUBJECT}</td>
                </tr>
            )}
            </tbody>
        </Table>;
    };

    const results = mode === "Done" ? table(data) :
        mode === "Searching" ? <ProgressBar>Searching...</ProgressBar> : "";

    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    <label>Search Conversations:</label>
                    <FormControl type="text" value={searchTerm} onChange={handleChange}/>
                </FormGroup>
                <FormCheck>

                    <FormCheckInput type="checkbox" checked={sentOnly} onChange={handleCheckBoxChange}/>
                    <FormCheckLabel>Sent only</FormCheckLabel>
                </FormCheck>
                <Button type="submit">Search</Button>
            </form>
            {results}
        </Container>
    );
}

function Index() {
    return (
        <Container>
            <Dashboard/>
        </Container>
    );
}

const LoggedInContext = React.createContext();

function App() {
    window.document.title = "MailMetadata";

    const [isSignedIn, setIsSignedIn] = React.useState(transposit.isSignedIn());
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        if (transposit.isSignedIn()) {
            setIsSignedIn(true);
        }
    }, []);
    React.useEffect(() => {
        if (window.location.pathname === '/handle-signin') {
            transposit.handleSignIn().then(() => {
                setIsSignedIn(true);
                window.location.pathname = '/';
            });
        }
    }, []);
    React.useEffect(() => {
        if (isSignedIn) {
            transposit.loadUser().then(u => setUser(u));
        }
    }, [isSignedIn]);

    if (!isSignedIn) {
        return (
            <Router>
                <Route component={SignIn}/>
            </Router>
        );
    }

    return (
        <LoggedInContext.Provider value={{isSignedIn: isSignedIn, user: user}}>
            <Router>
                <Navigation/>
                <Route path="/" exact component={Index}/>
                <Route path="/contacts" exact component={Contacts}/>
                <Route path="/conversations" exact component={Conversations}/>
            </Router>
        </LoggedInContext.Provider>
    );
}

export default App;
